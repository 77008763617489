import moment from 'moment';

export const olderAge = 120;

export const today = moment().startOf('day');

const minAge = moment().subtract(olderAge, 'years').startOf('day');

export const afterOnehour = moment().add(1, 'hour');

export const afterOneYear = moment().add(1, 'year').endOf('day');

export const twoDaysBefore = (date) => moment(date).subtract(2, 'days');

export const isFlexiAndTwoDaysBeforeTrip = (isFlexiSelected, flexiDateTime) =>
  isFlexiSelected && moment().isBefore(flexiDateTime);

export const defaultDateOrDate = (date, defaultDate) =>
  dateIsSameOrAfterToday(date) ?
    date.isSame(moment(today).add(1, 'days')) ? defaultDate : date :
    defaultDate;

export const dateIsSameOrAfterToday = (date) =>
  moment(date).isSameOrAfter(today);

export const dateIsfterADate = (date1, date2) =>
  moment(date1).isSameOrAfter(date2);

export const obDateIsSameOrAfterTodayAndIbDateIsAfterObDate = (obDate, ibDate) =>
  moment(obDate).isSameOrAfter(today) &&
  moment(ibDate).isSameOrAfter(obDate);

export const getTypeNextDayArrival = (departure, arrival) => {
  let isNextDayArrival = false;
  let isMidnightArrival = false;
  if (departure.getDate() !== arrival.getDate()) {
    if (arrival.getHours() <= 5) {
      isMidnightArrival = true;
    } else {
      isNextDayArrival = true;
    }
  }

  return {
    isNextDayArrival,
    isMidnightArrival
  };
};

export const presentYear = moment().format('YY');

export const setToNillHMS = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);

export const minBirthDate = minAge.toDate();

export const maxBirthDate = today.startOf('day').toDate();

export const longDateFormat = (type) => moment.localeData().longDateFormat(type);

export const dateSeparator = longDateFormat('L').match(/[^a-z]+/gi)[0];

export const datePickerFormat = `DD ${dateSeparator} MM ${dateSeparator} YYYY`;

const formatYear = (num, starting) => {
  const number = `${num}`;
  const firsNum = number.charAt(0);
  const secNum = number.charAt(1);
  const startingWith = starting.reduce((acc, item) => {
    return acc || (firsNum === item.charAt(0) && ['', item.charAt(1)].includes(secNum));
  }, false);

  if (startingWith) {
    return num;
  }

  return num.substr(0, number.length === 1 ? 0 : 1);
};

export const formatNumber = (num, from, limit) => {
  let number = Number(num) > from && Number(num) < 10 && num.length === 1 ? '0' + num : num;

  if (Number(number) > limit){
    number = number.substr(1, 2);
    number = Number(number) > from && Number(number) < 10 && number.length === 1 ? '0' + number : number;
  }

  return number;
};

export const formatDate = ({value, min, max}) => {
  const pattern = new RegExp('^[\\d \\' + dateSeparator + ']*$');
  if (pattern.test(value)) {
    let dateValue = value;
    if (/\D$/.test(dateValue)) {
      dateValue = dateValue.substr(0, dateValue.length - 3);
    }
    dateValue = dateValue.replaceAll(' ','');
    const arr = dateValue.split(dateSeparator);

    const minimum = moment(min).subtract(1, 'years');
    const maximum = moment(max);

    if (arr[0]) {
      arr[0] = formatNumber(arr[0], 3, 31);
    }

    if (arr[1]) {
      arr[1] = formatNumber(arr[1], 1, 12);
    }

    if (arr[2]) {
      arr[2] = formatYear(
        arr[2],
        [minimum.format('YYYY').substring(0, 2), maximum.format('YYYY').substring(0, 2)]
      );
    }

    const result = arr.map((val, index) => {
      return val.length == 2 && index < 2 ? val + ` ${dateSeparator} ` : val;
    });

    const val = result.join('').substr(0, 14);
    if (val.length === 14) {

      const valDate = moment(val, datePickerFormat);

      if (!valDate.isValid() || valDate.diff(minimum, 'y') < 1 || maximum.diff(valDate, 'd') < 0) {
        return val.substr(0, 13);
      }
    }

    return val;
  }

  const valueNoLastChar = value?.substr(0, value.length - 1);
  if (pattern.test(valueNoLastChar)) {
    return valueNoLastChar;
  }

  return '';
};

export const validateDatetime = {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function (value) {
    if (!(moment.isMoment(value) || moment.isDate(value))) {
      return NaN;
    }
    return +moment.utc(value);
  },
  // Input is a unix timestamp
  format: function (value, options) {
    const format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
    return moment.utc(value).format(format);
  }
};

export const durationLeft = (duration) => {
  const getDuration = moment.duration(duration.diff(moment()));
  const durationMonths = getDuration.months();
  const durationDays = getDuration.days();
  const days = durationMonths > 0 ? durationMonths * 30 + durationDays : durationDays;
  const hours = days > 0 ? getDuration.hours() : durationDays * 24 + getDuration.hours();
  const minutes = getDuration.minutes();

  return ({
    d: days > 0 ? days : 0,
    h: hours > 0 ? (hours > 9 ? hours : '0' + hours) : 0,
    m: minutes > 0 ? (minutes > 9 ? minutes : '0' + minutes) : 0
  });
};

export const timeLeftDaysOrHours = (obj, {days_short, hours_short, minutes_short}) => {
  return obj.d > 0 ?
    `${obj.d}${days_short} ${obj.h}${hours_short}` :
    `${obj.h}${hours_short} ${obj.m}${minutes_short}`;
};
