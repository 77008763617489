import moment from 'moment';
import request from 'superagent';
import validate from 'validate.js';
import * as R from 'ramda/src/index';
import {isEmpty} from 'helpers/commonHelper';
import {saveQuery} from 'providers/storage';
import {
  today,
  afterOneYear,
  dateIsSameOrAfterToday,
  obDateIsSameOrAfterTodayAndIbDateIsAfterObDate,
  validateDatetime
} from 'helpers/time';
import {
  threeLetterCodePattern,
  datePattern,
  getThreeLetterCode
} from 'helpers/commonHelper';
import {cacheKey} from 'components/meta-results-tabs/components/results/common';
import Translation from 'lib/helpers/translation';

const maxSeatOccupancy = 9;
const minAdultsPerInfant = 1;

validate.validators.maxSeatOccupancy = function (value, options, key, attributes) {
  if (!validate.isDefined(value)) {
    return;
  }

  if (validate.isNumber(options)) {
    options = {value: options};
  }

  options = validate.extend({}, this.options, options);
  const message =
    options.message ||
    this.message ||
    'should be less than or equal to %{value}';

  if (!validate.isNumber(options.value)) {
    throw new Error('The value must be a number');
  }

  const {passengersAdult, passengersChild} = attributes;
  const totalNumberOfPassengers = passengersAdult + passengersChild;
  if (totalNumberOfPassengers > options.value) {
    return validate.format(message, {value: options.value});
  }
};

validate.validators.minAdultsPerInfant = function (value, options, key, attributes) {
  if (!validate.isDefined(value)) {
    return;
  }

  if (validate.isNumber(options)) {
    options = {value: options};
  }

  options = validate.extend({}, this.options, options);
  const message =
    options.message ||
    this.message ||
    'should be at most equal to Adult passengers';

  if (!validate.isNumber(options.value)) {
    throw new Error('The value must be a number');
  }

  const {passengersAdult, passengersInfant} = attributes;
  if (passengersAdult < passengersInfant * options.value) {
    return validate.format(message, {value: options.value});
  }
};

validate.validators.inequality = function (value, options, attribute, attributes) {
  if (!validate.isDefined(value)) {
    return;
  }

  if (validate.isString(options)) {
    options = {attribute: options};
  }
  options = validate.extend({}, this.options, options);
  const message = options.message ||
    this.message ||
    'should not be equal to %{attribute}';

  if (validate.isEmpty(options.attribute) || !validate.isString(options.attribute)) {
    throw new Error('The attribute must be a non empty string');
  }

  const otherValue = validate.getDeepObjectValue(attributes, options.attribute);
  const comparator = options.comparator || function (v1, v2) {
    return v1 !== v2;
  };

  if (!comparator(value, otherValue, options, attribute, attributes)) {
    return validate.format(message, {attribute: validate.prettify(options.attribute)});
  }
};

validate.validators.notBefore = function (value, options, attribute, attributes) {
  if (!validate.isDefined(value)) {
    return;
  }

  if (validate.isString(options)) {
    options = {attribute: options};
  }
  options = validate.extend({}, this.options, options);
  const message = options.message ||
    this.message ||
    'should not be before %{attribute}';

  if (validate.isEmpty(options.attribute) || !validate.isString(options.attribute)) {
    throw new Error('The attribute must be a non empty string');
  }

  const otherValue = validate.getDeepObjectValue(attributes, options.attribute);
  const comparator =
    options.comparator
    || function (v1, v2) {
      return moment(v1).isAfter(moment(v2));
    };

  if (comparator(value, otherValue, options, attribute, attributes)) {
    return validate.format(message, {attribute: validate.prettify(options.attribute)});
  }
};

validate.validators.airportCode = function (value, options) {
  if (!validate.isDefined(value)) {
    return;
  }

  if (validate.isString(options)) {
    options = {attribute: options};
  }

  options = validate.extend({}, this.options, options);
  const message =
    options.message ||
    this.message ||
    'should be 3 letters or them into parentheses';

  if (value.length !== 3) {
    if (!value.match(options.pattern)) {
      return validate.format(message, {value: options.value});
    }
  }
};

validate.extend(validate.validators.datetime, validateDatetime);

validate.validators.type = function (value, options) {
  if (typeof options === 'object' && options.clazz) {
    return value instanceof options.clazz ? null : ' is not of type "' + options.clazz.name + '"';
  }

  if (!validate.validators.type.checks[options]) {
    throw new Error('Could not find validator for type ' + options);
  }
  return validate.validators.type.checks[options](value) ? null : ' is not of type "' + options + '"';
};

validate.validators.type.checks = {
  Object: function (value) {
    return validate.isObject(value) && !validate.isArray(value);
  },
  Array: validate.isArray,
  Integer: validate.isInteger,
  Number: validate.isNumber,
  String: validate.isString,
  Date: validate.isDate,
  Boolean: validate.isBoolean
};

const searchQueryConstraints = {
  dep: {
    presence: {
      message: Translation.tr('at_depAirportEmpty', 'searchMaskErrors'),
      allowEmpty: false
    },
    type: 'String',
    length: {
      minimum: 3,
      message: Translation.tr('at_depAirportInvalid', 'searchMaskErrors')
    },
    inequality: {
      attribute: 'arr',
      message: Translation.tr('at_depArrAirportsIdentical', 'searchMaskErrors')
    },
    airportCode: {
      pattern: threeLetterCodePattern,
      message: Translation.tr('at_depAirportInvalid', 'searchMaskErrors')
    }
  },
  arr: {
    presence: {
      message: Translation.tr('at_arrAirportEmpty', 'searchMaskErrors'),
      allowEmpty: false
    },
    type: 'String',
    length: {
      minimum: 3,
      message: Translation.tr('at_arrAirportInvalid', 'searchMaskErrors')
    },
    inequality: {
      attribute: 'dep',
      message: Translation.tr('at_depArrAirportsIdentical', 'searchMaskErrors')
    },
    airportCode: {
      pattern: threeLetterCodePattern,
      message: Translation.tr('at_arrAirportInvalid', 'searchMaskErrors')
    }
  },
  ibDate: {
    presence: {
      message: Translation.tr('at_ibDateInvalid', 'searchMaskErrors')
    },
    datetime: {
      latest: afterOneYear,
      earliest: today,
      message: Translation.tr('at_ibDateInPast', 'searchMaskErrors')
    }
  },
  obDate: {
    presence: {
      message: Translation.tr('at_obDateInvalid', 'searchMaskErrors')
    },
    datetime: {
      latest: afterOneYear,
      earliest: today,
      message: Translation.tr('at_ibDateInPast', 'searchMaskErrors')
    },
    notBefore: {
      attribute: 'ibDate',
      message: Translation.tr('at_ibDateSmallerThanObDate', 'searchMaskErrors')
    }
  },
  passengersAdult: {
    presence: {
      message: Translation.tr('at_passengersAdultNotNumber', 'searchMaskErrors')
    },
    numericality: {
      noStrings: true,
      onlyInteger: true,
      greaterThanOrEqualTo: 1,
      lessThanOrEqualTo: maxSeatOccupancy,
      message: Translation.tr('at_passengersAdultNotInRange', 'searchMaskErrors')
    },
    maxSeatOccupancy: {
      value: maxSeatOccupancy,
      message: Translation.tr('at_passengersAdultNotInRange', 'searchMaskErrors')
    }
  },
  passengersChild: {
    presence: {
      message: Translation.tr('at_passengersChildNotNumber', 'searchMaskErrors')
    },
    numericality: {
      noStrings: true,
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: maxSeatOccupancy,
      message: Translation.tr('at_passengersChildNotInRange', 'searchMaskErrors')
    },
    maxSeatOccupancy: {
      value: maxSeatOccupancy,
      message: Translation.tr('at_passengersChildNotInRange', 'searchMaskErrors')
    }
  },
  passengersInfant: {
    presence: true,
    numericality: {
      noStrings: true,
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: maxSeatOccupancy,
      message: Translation.tr('at_passengersInfantNotNumber', 'searchMaskErrors')
    },
    minAdultsPerInfant: {
      value: minAdultsPerInfant,
      message: Translation.tr('at_passengersInfantNotInRange', 'searchMaskErrors')
    }
  },
  isRoundtrip: {
    presence: true,
    type: 'Boolean'
  },
  extendedDates: {
    presence: true,
    type: 'Boolean'
  },
  seatClass: {
    presence: {
      allowEmpty: true
    },
    type: 'String'
  },
  airlineCode: {
    presence: {
      allowEmpty: true
    },
    type: 'String'
  }
};

function validateQuery(query) {
  return new Promise(function (resolve, reject) {
    const errors = validate(query, searchQueryConstraints, {fullMessages: false});
    if (errors) {
      return reject({type: 'validation errors', errors});
    }
    resolve(query);
  });
}

function fetchResultsUrl(query) {
  return validateQuery(query)
    .then((query) => {
      const {
        dep, arr, obDate, ibDate, isRoundtrip, passengersInfant, passengersAdult,
        passengersChild, directFlightsOnly, extendedDates, airlineCode, seatClass
      } = query;

      const queryData = {
        dep,
        arr,
        obDate: moment(obDate).format('YYYY-MM-DD'),
        ibDate: moment(ibDate).format('YYYY-MM-DD'),
        isRoundtrip: isRoundtrip ? '1' : '0',
        passengersInfant,
        passengersAdult,
        passengersChild,
        directFlightsOnly: directFlightsOnly ? '1' : '0',
        extendedDates: extendedDates ? '1' : '0',
        airlineCode: airlineCode,
        seatClass: seatClass
      };
      saveQuery(queryData, cacheKey);

      return R.reject(isEmpty, [
        '/results#search',
        getThreeLetterCode(dep),
        getThreeLetterCode(arr),
        `obDate/${queryData.obDate}`,
        `ibDate/${queryData.ibDate}`,
        `isRoundtrip/${queryData.isRoundtrip}`,
        `passengersAdult/${passengersAdult}`,
        `passengersChild/${passengersChild}`,
        `passengersInfant/${passengersInfant}`,
        `directFlightsOnly/${queryData.directFlightsOnly}`,
        airlineCode ? `airlineCode/${airlineCode}` : undefined,
        seatClass ? `class/${seatClass}` : undefined,
        `extendedDates/${queryData.extendedDates}`
      ]).join('/');
    });
}

function fetchResults(query) {
  return validateQuery(query)
    .then(function (query) {
      return request
        .post('/api/v1/flights/results')
        .set(query);
    })
    .then(function (result) {
      return request
        .get('/api/v1/flights/results')
        .query({searchID: result.body.results.ID});
    })
    .then(function (results) {
      return results.results;
    });
}

const isQueryValid = ({dep, arr, obDate, ibDate, isRoundtrip, passengersAdult, passengersChild,
  passengersInfant, directFlightsOnly, extendedDates}) => {
  if (!dep || dep.length !== 3) {
    return false;
  }
  if (!arr || arr.length !== 3) {
    return false;
  }
  if (!isRoundtrip || typeof isRoundtrip !== 'string') {
    return false;
  }
  const obDateMoment = datePattern.test(obDate) ? moment(obDate) : '';
  if (!obDate || !obDateMoment.isValid() || !dateIsSameOrAfterToday(obDateMoment)
    || obDateMoment.isAfter(afterOneYear)) {
    return false;
  }
  const ibDateMoment = datePattern.test(ibDate) ? moment(ibDate) : '';
  if (!ibDate || !ibDateMoment.isValid() ||
    !obDateIsSameOrAfterTodayAndIbDateIsAfterObDate(obDateMoment, ibDateMoment)
    || ibDateMoment.isAfter(afterOneYear)) {
    return false;
  }
  const adults = parseInt(passengersAdult, 10);
  if (!passengersAdult || adults < 0 || adults > 9 || typeof adults !== 'number') {
    return false;
  }
  const children = parseInt(passengersChild, 10);
  if (!passengersChild || children < 0 || children > 8 || typeof children !== 'number') {
    return false;
  }
  const infants = parseInt(passengersInfant, 10);
  if (!passengersInfant || infants < 0 || infants > 9 || infants > adults || typeof infants !== 'number') {
    return false;
  }
  if (!directFlightsOnly || typeof directFlightsOnly !== 'string') {
    return false;
  }
  if (!extendedDates || typeof extendedDates !== 'string') {
    return false;
  }

  return true;
};

const createHash = (query) => {
  return ['dep', 'arr', 'obDate', 'ibDate', 'isRoundtrip', 'passengersAdult', 'passengersChild',
    'passengersInfant', 'directFlightsOnly', 'airlineCode', 'class', 'extendedDates']
    .reduce((accumulator, key) => {
      if (key === 'dep' || key === 'arr') {
        accumulator += '/' + query[key];
      } else if (query[key]) {
        accumulator += '/' + key + '/';
        accumulator += query[key];
      }
      
      return accumulator;
    }, '#search');
};

export {fetchResultsUrl, validateQuery, fetchResults, isQueryValid, createHash};
