import {isEmpty, isString, isObject, forEach} from 'lodash';
import _tp24 from 'tp24';

const translation = {
  tr: function (value, group, defaultValue) {
    const translations = this._getTranslationGroup(group);
    const result = this._tr(value, translations);

    return result || defaultValue || value;
  },

  group: function (group) {
    return this._getTranslationGroup(group);
  },

  _tr: function (value, translations, depth) {
    const _this = this;
    let result = null;
    const max_depth = 5;
    depth = depth || 0;

    if (isEmpty(translations)) {
      return result;
    }

    if (translations[value] && isString(translations[value])) {
      // Pattern to resolve: { 'value': 'translated value' }
      result = translations[value];
    } else if (translations[value] && translations[value].translated) {
      // Pattern to resolve: { 'value': { 'translated': 'translated value'} }
      result = translations[value].translated;
    } else if (translations.translated && translations.value &&
        translations.value === value) {
      // Pattern to resolve: { 'value': 'value', 'translated': 'translated value' }
      result = translations.translated;
    } else if (isObject(translations)) {
      // Recursively search children
      depth++;
      if (depth > max_depth) {
        return result;
      }
      forEach(translations, function (group) {
        if (!result) {
          result = _this._tr(value, group, depth);
        }
      });
    }

    return result;
  },

  _getTranslationGroup: function (group) {
    if (isEmpty(_tp24.translations)) {
      throw new Error('_tp24.translations is missing');
    }

    let translations = _tp24.translations;

    if (!isString(group)) {
      return translations;
    }

    group.split('.').every(function (key) {
      if (translations[key]) {
        translations = translations[key];
        return true;
      }
      return false;
    });
    return translations;
  }
};

export default translation;
