import moment from 'moment'; 

export function formatDuration(duration, {hours_short, minutes_short}) {
  const hours = duration.days() * 24 + duration.hours();
  const minutes = duration.minutes();
  const obj = {
    h: hours > 9 ? hours : '0' + hours,
    m: minutes > 9 ? minutes : '0' + minutes
  };
  return `${obj.h}${hours_short} ${obj.m}${minutes_short}`;
}

export const getBaggageText = (baggageAllowance, {manyWithoutWeightShort, baggageIncluded}) => (
  baggageAllowance === 1 ? baggageIncluded :
    manyWithoutWeightShort.replace('{numberBaggages}', baggageAllowance)
);

export function stopsText(number, {stop, stops, nonStop}) {
  if (number === 1) {
    return `${number} ${stop}`;
  } else if (number > 1) {
    return `${number} ${stops}`;
  }
  return nonStop;
}

export const combineDurations = (firstSeg, secondSeg) => (
  moment.duration(firstSeg).add(moment.duration(secondSeg))
);

export const cacheKey = 'flight-queries';

export const cacheKeyAccommodation = 'flight-accomodation';
