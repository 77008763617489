import * as R from 'ramda/src/index';
import storage from 'utils/storage';
import {dateIsSameOrAfterToday} from 'helpers/time';

const cache = storage.createStore({name: 'ferrySearchQueries'});
const getRecentQueries = (cacheKey) =>
  cache.getItem(cacheKey).then((queries) => {
    if (queries && queries.length) {
      return queries.filter(({obDate, ibDate}) =>
        dateIsSameOrAfterToday(obDate) && dateIsSameOrAfterToday(ibDate));
    }
    return [];
  });

const saveQuery = (newQuery, cacheKey) => {
  getRecentQueries(cacheKey).then((queries) => {
    const index = R.findLastIndex((query) => R.equals(query, newQuery))(queries);
    if (index > 0)  {
      queries.splice(index, 1);
    }
    if (index !== 0) {
      queries.unshift(newQuery);
    }
    if (queries.length > 5) {
      queries.pop();
    }
    cache.setItem(cacheKey, queries);
  });
};

export {getRecentQueries, saveQuery};
